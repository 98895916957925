import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Papa from "papaparse";

// const APIURL_PITSWAP = "https://pitswap-api.herokuapp.com/api/";
const APIURL_PITSWAP = "https://api.pitsbi.io/api/";

const TIMEOUT_REFRESH = 60 * 1000;

const Navigation = () => {
	const [nb_buy, setNb_buy] = useState(0);
	const [nb_sell, setNb_sell] = useState(0);
	const [infoSell, setInfoSell] = useState("");
	const [infoBuy, setInfoBuy] = useState("");

	// console.log("nb_sell="+nb_sell);

	// ----------------------------------------------
	// ---- Timer pour mise à jour des offres    ----
	// ----------------------------------------------
	const [state, setstate] = React.useState({ num: 0 });
	const counter = React.useRef(0);
	React.useEffect(() => {
		counter.current += 1;
		const timer = setTimeout(() => setstate({ num: state.current }), TIMEOUT_REFRESH);
		return () => clearTimeout(timer);
	}, [state]);

	useEffect(() => {
		fetch(APIURL_PITSWAP + "secondary_market_count").then((reponse) => {
			if (reponse.ok) {
				reponse.json().then((body) => {
					const offers_count = body[0];
					setNb_buy(offers_count.buy);
					setNb_sell(offers_count.sell);
					setInfoBuy("YAM: " + offers_count.yam.buy + "\nPitswap: " + offers_count.swapcat.buy);
					setInfoSell("YAM: " + offers_count.yam.sell + "\nPitswap: " + offers_count.swapcat.sell);
				});
			}
		});
	}, [state]);

	return (
		<div>
			<div className='navigation'>
				<div>
					<ul>
						<NavLink to='/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
							<li>Dashboard</li>
						</NavLink>
						{/* <NavLink to='/token_updates' className={(nav) => (nav.isActive ? "nav-active" : "")}>
							<li>Updates</li>
						</NavLink> */}
						<NavLink to='/swap_token' className={(nav) => (nav.isActive ? "nav-active" : "")}>
							<li>Swap</li>
						</NavLink>
						<NavLink to='/achat_token' className={(nav) => (nav.isActive ? "nav-active" : "")}>
							<label title={infoBuy} color={"green"}>
								<li>Purchases ({nb_buy})</li>
							</label>
						</NavLink>
						<NavLink to='/vente_token' className={(nav) => (nav.isActive ? "nav-active" : "")}>
							<label title={infoSell} color={"green"}>
								<li>Sales ({nb_sell})</li>
							</label>
						</NavLink>
						<NavLink to='/make_offer' className={(nav) => (nav.isActive ? "nav-active" : "")}>
							<li>Make Offer</li>
						</NavLink>
						<NavLink to='/about' className={(nav) => (nav.isActive ? "nav-active" : "")}>
							<li>About</li>
						</NavLink>
						<NavLink to='/cryptalloc' className={(nav) => (nav.isActive ? "nav-active" : "")}>
							<li>Community links</li>
						</NavLink>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Navigation;
