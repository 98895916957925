import React, { useEffect, useState } from "react";

// const APIURL_PITSWAP = "https://pitswap-api.herokuapp.com/api/";
const APIURL_PITSWAP = "https://api.pitsbi.io/api/";

var imgNew = "/images/new transparant.png";

export default function TokenUpdates(props) {
	const [data, setData] = useState(null); // This state will store the parsed data
	const [maxdata, setMaxdata] = useState(0); // This state will store the parsed data
	const [getdata, setGetdata] = useState(0); // This state will store the parsed data
	const [realTokenSearch, setRealTokenSearch] = useState(JSON.parse(localStorage.getItem("update_realTokenSearch")) || "");
	const [keySearch, setKeySearch] = useState(JSON.parse(localStorage.getItem("update_keySearch")) || "");

	// ----------------------------------------------
	// ---- Reccupération de la liste des offres ----
	// ----------------------------------------------
	useEffect(() => {
		fetch(APIURL_PITSWAP + "realTokens/api_nb_updates").then((reponse) => {
			if (reponse.ok) {
				reponse.json().then((body) => {
					setMaxdata(body);
				});
			}
		});

		fetch(APIURL_PITSWAP + "realTokens/api_updates").then((reponse) => {
			if (reponse.ok) {
				reponse.json().then((body) => {
					setData(body);
					setGetdata(getdata + 100);
				});
			}
		});
	}, []);

	useEffect(() => {
		console.log("getdata", getdata,"maxdata", maxdata);
		if (getdata < maxdata) {
			fetch(APIURL_PITSWAP + `realTokens/api_updates/${getdata}`).then((reponse) => {
				if (reponse.ok) {
					reponse.json().then((body) => {
						var new_data = data.concat(body);
						setData(new_data);
						setGetdata(getdata + 100);
					});
				}
			});
		}
	}, [data]);

	useEffect(() => {
		localStorage.setItem("update_realTokenSearch", JSON.stringify(realTokenSearch));
	}, [realTokenSearch]);
	useEffect(() => {
		localStorage.setItem("update_keySearch", JSON.stringify(keySearch));
	}, [keySearch]);

	// console.log("data", data);

	if(!data) return <></>;

	return (
		<div className='dashboard'>
			<div className='radio-container'>
				<ul>
					<li id='text'>
						<label htmlFor='realTokenSearch'>Filter by RealToken Name : </label>
						<input
							type='search'
							name='realTokenSearch'
							value={realTokenSearch}
							placeholder='Token name ?'
							onFocus={(e) => e.target.select()}
							onChange={(e) => setRealTokenSearch(e.target.value)}
						/>
					</li>
					<li id='text'>
						<label htmlFor='keySearch'>Filter by Key Value : </label>
						<input
							type='search'
							name='keySearch'
							value={keySearch}
							placeholder='Token name ?'
							onFocus={(e) => e.target.select()}
							onChange={(e) => setKeySearch(e.target.value)}
						/>
					</li>
				</ul>
			</div>
			<div className='table-container'>
				<table>
					<thead>
						<tr>
							<th width='20%'>RealToken Name</th>
							<th width='20%'>Key Value</th>
							<th width='20%'>New Value</th>
							<th width='20%'>Old Value</th>
							<th width='20%'>Date</th>
						</tr>
					</thead>
					<tbody>
						{data
							.filter((a) => (realTokenSearch ? a.shortName.toLowerCase().includes(realTokenSearch.toLowerCase()) : true))
							.filter((a) => (keySearch ? a.key.toLowerCase().includes(keySearch.toLowerCase()) : true))
							.map((update, index) => (
								<tr>
									<td>
										<a href={update.marketplaceLink} target='_blank' rel='noopener noreferrer'>
											{update.shortName}
										</a>
									</td>
									<td>{update.key}</td>
									<td>{update.new_value}</td>
									<td>{update.old_value}</td>
									<td>
										<div>
											{/* {update.is_new && <img height='20px' src={imgNew} alt={imgNew} />}
												{"    "} */}
											{update.timsync.split("T")[0] + " " + update.timsync.split("T")[1].split(".")[0]}
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
